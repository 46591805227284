import { AuthPage, ThemedTitleV2 } from '@refinedev/antd'
import {
  RegisterFormTypes,
  useIsAuthenticated,
  useRegister,
  useLogout,
  useTranslate,
  useLink,
  useRouterType,
  useRouterContext,
  useActiveAuthProvider,
} from '@refinedev/core'
import { Typography, Card, theme, Form, Button, Input } from 'antd'
import { AppIcon } from 'components/app-icon'
import React, { CSSProperties } from "react";

const { Text, Title } = Typography;
const { useToken } = theme

const SignupCard: React.FC = () => {
  const { token } = useToken()
  const [form] = Form.useForm()
  const t = useTranslate()

  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const authProvider = useActiveAuthProvider();
  const { mutate: register, isLoading } = useRegister<RegisterFormTypes>({
      v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy)
  });
  const { mutate: logout } = useLogout()
  
  const { data } = useIsAuthenticated()

  const titleStyles: CSSProperties = {
    textAlign: "center",
    marginBottom: 0,
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    overflowWrap: "break-word",
    hyphens: "manual",
    textOverflow: "unset",
    whiteSpace: "pre-wrap",
    color: token.colorPrimaryTextHover
  };

  const cardStyles: CSSProperties = {
    maxWidth: "400px",
    margin: "auto",
    padding: "32px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)",
    backgroundColor: token.colorBgElevated
  };

  const isConfirmOtp = data?.redirectTo === '/confirm_otp_required' ? true : false;
  const isPassword = data?.redirectTo === '/password_required' ? true : false;

  const cardTitle: React.ReactNode = (
    <Title
      level={3}
      style={{ ...titleStyles }}
    >
      {isConfirmOtp ? t('pages.signup.confirmOtp') : null}
      {isPassword ? t('pages.signup.setPassword') : null}
      {!isConfirmOtp && !isPassword ? t("pages.signup.title") : null}
    </Title>
  )
  
  return (
    <Card
      title={cardTitle}
      headStyle={{ borderBottom: 0, padding: 0 }}
      bodyStyle={{ padding: 0, marginTop: '32px' }}      
      style={{ ...cardStyles }}
    >
      <Form 
        layout="vertical" 
        form={form} 
        onFinish={(values) => register(values)}
        requiredMark={false}
        initialValues={{}}
      >
        {isPassword ? (
          <>
            <Form.Item
              name="password"
              label={t("pages.login.fields.password")}
              rules={[{ required: true }]}
            >
              <Input
                type="password"
                placeholder="●●●●●●●●"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                block
              >
                {t('pages.signup.buttons.confirm')}
              </Button>
            </Form.Item>            
          </>
        ) : null}

        {isConfirmOtp ? (
          <>             
            <Form.Item
              name="token"
              label={t('pages.signup.fields.confirmOtp.label')}
              rules={[
                { required: true },
                { 
                  min: 4, 
                  max: 8,
                  message: t("pages.signup.errors.validToken")
                }
              ]}
            >
              <Input
                size="large"
                placeholder={t('pages.signup.fields.confirmOtp.placeholder')}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                block
              >
                {t('pages.signup.buttons.confirm')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                size="large"
                loading={isLoading}
                block
                onClick={() => logout()}
              >
                {t('pages.signup.buttons.goToStart')}
              </Button>
            </Form.Item>
          </>
        ) : null}

        {!isConfirmOtp && !isPassword ? (
          <>
            <Form.Item
              name="phone"
              label={t("pages.login.fields.phone")}
              rules={[
                { required: true },
                { 
                  min: 11,
                  max: 12,
                  message: t("pages.login.errors.validPhone")
                }
              ]}
            >
              <Input
                size="large"
                placeholder={t("pages.login.fields.phone")}
              />
            </Form.Item>            
            <Form.Item>
              <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
              >
                  {t("pages.signup.buttons.confirm")}
              </Button>
            </Form.Item>
            <div 
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
              }}
            >
              <Text
                  style={{
                      fontSize: 12,
                      marginLeft: "auto",
                  }}
              >
                  {t("pages.signup.buttons.haveAccount")}
                  {" "}
                  <ActiveLink
                      style={{
                          fontWeight: "bold",
                          color: token.colorPrimaryTextHover,
                      }}
                      to="/login"
                  >
                      {t("pages.login.buttons.signin")}
                  </ActiveLink>
              </Text>                
            </div>            
          </>
        ) : null}        
      </Form>      
    </Card>    
  )
}

export const Signup = () => {
  return (
    <AuthPage
      title={<ThemedTitleV2 collapsed={false} text="Fortune Wallet" icon={<AppIcon />} />}
      renderContent={(_, title) => (
        <>
          {title}
          <SignupCard />
        </>
      )}
    />
  )
}
