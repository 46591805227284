import {
  useTranslate,
} from '@refinedev/core'

import {
  Typography,
} from 'antd'

export const DashboardShow: React.FC = () => {
  const t = useTranslate()
  
  return (
    <>
      <Typography.Title level={3}>{t('dashboard.title')}</Typography.Title>      
    </>
  )
}
