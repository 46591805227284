import React from 'react'
import { IResourceComponentsProps, useShow, useCan, useTranslate } from '@refinedev/core'
import { ListButton, EditButton, Show } from '@refinedev/antd'
import { Col, Descriptions, Row, Tabs, TabsProps } from 'antd'

import { IMerchant } from 'interfaces'
import { CopyText } from 'components/CopyText'
import DateField from 'components/DateField'
import { UsersList } from './UsersList'

export const MerchantShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IMerchant>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: canListUsers } = useCan({
    resource: 'users',
    action: 'list',
  })

  let tabsItems: TabsProps['items'] = []
  canListUsers?.can &&
    tabsItems.push({
      key: '1',
      label: t('merchants.titles.users'),
      children: record && !isLoading ? <UsersList merchantId={record.id} /> : null,
    })

  const headerButtons = () => {
    return (
      <>
        <ListButton
          resource="merchants"
          title={t('merchants.merchants')}
        />
        {record &&
          <EditButton
            type='primary'
            resource="merchants"
            recordItemId={record.id}
            title={t('buttons.edit')}
          />
        }
      </>
    )
  }

  return (
    <>
      <Show
        headerButtons={headerButtons}
        resource="merchants"
        isLoading={isLoading}
        title={record?.name || ''}
      >
        <Row>
          <Col span={12}>
            <Descriptions column={1}>
              <Descriptions.Item label={t('merchants.fields.id')}>
                <CopyText value={record?.id} />
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.timezone')}>
                {record?.timezone}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.currency')}>
                {record?.currency}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.created_at')}>
                <DateField value={record?.created_at} />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Show>
      <Tabs defaultActiveKey="1" items={tabsItems} />
    </>
  )
}
