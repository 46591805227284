import { AuthPage, ThemedTitleV2 } from '@refinedev/antd'
import {
  LoginFormTypes,
  useIsAuthenticated,
  useLogin,
  useLogout,
  useTranslate,
  useLink,
  useRouterType,
  useRouterContext,
  useActiveAuthProvider
} from '@refinedev/core'
import { Typography, Card, theme, Form, Button, Input } from 'antd'
import { AppIcon } from 'components/app-icon'
import React, { CSSProperties } from "react";

const { Text, Title } = Typography;
const { useToken } = theme

const LoginCard: React.FC = () => {
  const { token } = useToken()
  const [form] = Form.useForm()
  const t = useTranslate()

  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
      v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy)
  });
  const { mutate: logout } = useLogout()
  
  const { data } = useIsAuthenticated()

  const titleStyles: CSSProperties = {
    textAlign: "center",
    marginBottom: 0,
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    overflowWrap: "break-word",
    hyphens: "manual",
    textOverflow: "unset",
    whiteSpace: "pre-wrap",
    color: token.colorPrimaryTextHover
  };

  const cardStyles: CSSProperties = {
    maxWidth: "400px",
    margin: "auto",
    padding: "32px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)",
    backgroundColor: token.colorBgElevated
  };

  const isTwoFactor = data?.redirectTo === '/two_factor' ? true : false;

  const cardTitle: React.ReactNode = (
    <Title
      level={3}
      style={{ ...titleStyles }}
    >
      {isTwoFactor ? t('pages.login.twoFactor') : t("pages.login.title")}
    </Title>
  )
  
  return (
    <Card
      title={cardTitle}
      headStyle={{ borderBottom: 0, padding: 0 }}
      bodyStyle={{ padding: 0, marginTop: '32px' }}      
      style={{ ...cardStyles }}
    >
      <Form 
        layout="vertical" 
        form={form} 
        onFinish={(values) => login(values)}
        requiredMark={false}
        initialValues={{}}
      >
        {isTwoFactor ? (
          <>
            <Form.Item
              name="code"
              label={t('pages.login.fields.code.label')}
              rules={[
                { required: true },
                { 
                  len: 6,
                  message: t("pages.login.errors.validCode")
                }
              ]}
            >
              <Input
                size="large"
                placeholder={t('pages.login.fields.code.placeholder')}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                block
              >
                {t('pages.login.buttons.confirm')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                size="large"
                loading={isLoading}
                block
                onClick={() => logout()}
              >
                {t('pages.login.buttons.logout')}
              </Button>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="phone"
              label={t("pages.login.fields.phone")}              
              rules={[
                { required: true },
                { 
                  min: 11,
                  max: 12,
                  message: t("pages.login.errors.validPhone")
                }
              ]}              
            >
              <Input
                size="large"
                placeholder={t("pages.login.fields.phone")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("pages.login.fields.password")}              
              rules={[{ required: true }]}
            >
              <Input
                type="password"
                placeholder="●●●●●●●●"
                size="large"
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px"
              }}
            >
              <ActiveLink
                style={{
                  color: token.colorPrimaryTextHover,
                  fontSize: "12px",
                  marginLeft: "auto"
                }}
                to="/forgot-password"
              >
                {t("pages.login.buttons.forgotPassword")}
              </ActiveLink>               
            </div>
            <Form.Item>
              <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
              >
                  {t("pages.login.buttons.signin")}
              </Button>
            </Form.Item>
          </>
        )}        
      </Form>
      {!isTwoFactor ? (
        <div style={{ marginTop: 8 }}>         
          <Text style={{ fontSize: 12 }}>
            {t("pages.login.buttons.noAccount")}
            {" "}
            <ActiveLink
              to="/signup"
              style={{
                  fontWeight: "bold",
                  color: token.colorPrimaryTextHover,
              }}
            >
              {t("pages.signup.buttons.signup")}
            </ActiveLink>
          </Text>
        </div>
      ) : null}
    </Card>    
  )
}

export const Login = () => {
  return (
    <AuthPage
      title={<ThemedTitleV2 collapsed={false} text="Fortune Wallet" icon={<AppIcon />} />}
      renderContent={(_, title) => (
        <>
          {title}
          <LoginCard />
        </>
      )}
    />
  )
}
