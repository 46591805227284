import { useInvalidate, useTranslate } from '@refinedev/core'
import { useSelect, useModalForm } from '@refinedev/antd'
import { Select, Button, Modal, Form, Input } from 'antd'
import { IDealing, IMerchant, IRole } from 'interfaces'

const AddUserDealingButton: React.FC<{ id: string }> = ({ id }) => {
  const t = useTranslate()
  const invalidate = useInvalidate()

  const { selectProps: rolesSelectProps } = useSelect<IRole>({
    resource: 'roles',
    optionLabel: 'code',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: showModal,
  } = useModalForm<IDealing>({
    resource: 'dealings',
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: 'users',
        invalidates: ['list'],
      })
    },
  })

  return (
    <>
      <Button
        type="dashed"
        size="small"
        onClick={() => {
          showModal()
        }}
      >
        {t('users.titles.add_dealing')}
      </Button>
      <Modal {...createModalProps} title={t('dealings.titles.create')} width={400}>
        <Form {...createFormProps} layout="vertical">
          <Form.Item
            label={t('dealings.form.role.placeholder')}
            name="role_id"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...rolesSelectProps}
              showSearch
              allowClear
              placeholder={t('dealings.form.role.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="user_id" initialValue={id} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('dealings.form.merchant.label')}
            name="merchant_id"
            extra={t('dealings.form.merchant.extra')}
          >
            <Select
              {...merchantsSelectProps}
              allowClear
              showSearch
              placeholder={t('dealings.form.merchant.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddUserDealingButton
