import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { ListButton, Edit, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IUser } from 'interfaces'

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IUser>({
    action: 'edit',
    redirect: 'show',
  })

  const headerButtons = () => {
    return (
      <>
        <ListButton
          resource="users"
          title={t('users.users')}
        />
      </>
    )
  }

  return (
    <Edit
      headerButtons={headerButtons}
      resource="users"
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label={t('users.fields.email')} name="email">
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
