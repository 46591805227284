import { Typography } from 'antd'

const { Text } = Typography

export const CopyText: React.FC<{
  value?: string
  style?: React.CSSProperties
}> = ({
  value,
  style = {}
}) => {
    if (!value) {
      return <Text>–</Text>
    }

    return (
      <Text
        copyable={{ text: value, tooltips: value }}
        style={style}
      >
        {value}
      </Text>
    )
  }
