import dayjs from 'dayjs'
import { DateField as AntdDateField } from '@refinedev/antd'
import { Typography } from 'antd'

const { Text } = Typography

const DateField: React.FC<{
  value?: string
  format?: string
  ellipsis?: boolean
  style?: React.CSSProperties
}> = ({ value, format = 'YYYY.MM.DD HH:mm', ellipsis, style = {} }) => {
  if (!value) {
    return <Text style={Object.assign({ verticalAlign: 'bottom' }, style)}>…</Text>
  }

  const dayjsValue = dayjs.utc(value).tz()

  return (
    <AntdDateField
      value={dayjsValue}
      locales={dayjs.locale()}
      format={format}
      ellipsis
      style={style}
    />
  )
}

export default DateField
