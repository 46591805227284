import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps } from 'antd'
import { IAccount } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { TextField } from '@refinedev/antd'
import DateField from 'components/DateField'

const AccountsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IAccount> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 10,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IAccount>
        ellipsis
        dataIndex="id"
        width={100}
        key="id"
        title={t('accounts.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="balance"
        key="balance"
        width={150}
        title={t('accounts.fields.balance')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 2, record.currency)} />
        )}
      />
      <Table.Column<IAccount>
        width={100}
        dataIndex="currency"
        key="currency"
        title={t('accounts.fields.currency')}
      />
      <Table.Column<IAccount>
        width={200}
        dataIndex="created_at"
        key="created_at"
        title={t('accounts.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
    </Table>
  )
}

export default AccountsTable
