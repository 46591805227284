import { CloseCircleFilled } from '@ant-design/icons'
import { Button, Popconfirm, Typography, Tag } from "antd";
import { GetManyResponse, useCan, useDelete, useInvalidate, useTranslate } from "@refinedev/core";
import { IDealing, IMerchant } from 'interfaces'

const { Text } = Typography

interface UserDealingProps {
  dealing: IDealing
  merchantsData: GetManyResponse<IMerchant> | undefined
  readonly?: boolean
}

const UserDealing: React.FC<UserDealingProps> = ({
  dealing,
  merchantsData,
  readonly = false,
}) => {
  const t = useTranslate()
  const { mutate } = useDelete()
  const invalidate = useInvalidate()
  const { id, role } = dealing

  const merchant =
    merchantsData &&
    dealing.merchant_id &&
    merchantsData.data.find((item) => dealing.merchant_id === item.id)

  const onDelete = () => {
    mutate(
      {
        resource: 'dealings',
        id,
      },
      {
        onSuccess: () => {
          invalidate({
            resource: 'users',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const { data: canDeleteDealing } = useCan({
    resource: 'dealings',
    action: 'delete',
  })

  return (
    <Text code ellipsis style={{ display: 'flex', alignItems: 'center' }}>
      {merchant && <Tag color={'blue'}>{merchant.name}</Tag>}
      {role.code}
      {canDeleteDealing?.can && !readonly ? (
        <Popconfirm
          title={t('users.confirm.delete_dealing.title')}
          description={t('users.confirm.delete_dealing.description')}
          onConfirm={() => {
            onDelete()
          }}
          okText={t('users.confirm.delete_dealing.ok')}
          cancelText={t('users.confirm.delete_dealing.cancel')}
        >
          <Button
            size="small"
            icon={<CloseCircleFilled />}
            type="link"
            style={{ fontSize: 12 }}
          />
        </Popconfirm>
      ) : null}
    </Text>
  )
}

export default UserDealing
